* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

img {
  margin: 0;
  padding: 0;
}

.row {
  max-width: 100vw;
}

hr {
  height: 2px;
  background-color: #a7a7a7;
}

a {
  color: #000;
}

a:hover {
  transition: 0.5s;
}

@media (max-width: 40rem) {
  .column.column-60 {
    margin-left: 20px;
  }
  .row .column.column-60 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}
