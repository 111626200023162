.navContainer {
  height: 65px;
  display: flex;
  align-items: center;
  background-color: #2b2b2b;
  box-shadow: 0 0px 15px 0px #00000066;
  margin-bottom: 50px;
  font-size: 18px;
}

.navLink {
  margin-right: 25px;
}

.homeTitle:hover {
  color: #000;
}
